<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/toolManage' }"
        >产品列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>版本管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30" class="row">
        <span class="span1">基本信息</span>
      </el-row>
      <el-row :gutter="30" class="row1">
        <div class="span2">
          <div style="display: inline-block; margin-right: 200px">
            <p>
              <span>产品名称：{{ info.name }}</span>
            </p>
            <p>
              <span>产品类型：{{ info.type }}</span>
            </p>
          </div>
          <div style="display: inline-block">
            <p>
              <span>创建用户：{{ info.owner_name }}</span>
            </p>
            <p>
              <span>创建时间：{{ info.createtime }}</span>
            </p>
          </div>
        </div>
        <div class="span2">
          <span :title="info.describe">产品描述：{{ info.describe }}</span>
        </div>
      </el-row>
      <el-row :gutter="30" class="row3">
        <el-link :underline="false" class="link1" @click="Versions">
          <div v-if="activeIndex == '1'" style="color: #303133">版本列表</div>
          <div v-else style="color: grey">版本列表</div>
        </el-link>
        <el-link :underline="false" class="link2" @click="Docs">
          <div v-if="activeIndex == '2'" style="color: #303133">产品文档</div>
          <div v-else style="color: grey">产品文档</div>
        </el-link>
      </el-row>

      <div v-if="activeIndex == '1'">
        <el-row :gutter="30" class="row4">
          <div class="div11">
            <div style="float: left">
              <el-input
                placeholder="请输入搜索的版本号"
                v-model.trim="queryinfo.name"
                clearable
                @clear="getList"
                style="width: 230px"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="seachChange"
                ></el-button>
              </el-input>
            </div>
            <div style="float: left; margin-left: 20px">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                @click="AddVersion"
                >新增版本</el-button
              >
              <el-link
                type="primary"
                icon="el-icon-upload"
                style="margin-left: 100px"
                @click="UploadIcon"
                >上传系统图标</el-link
              >
              <el-link
                type="info"
                icon="el-icon-upload"
                style="margin-left: 40px"
                @click="ModeifyIcon"
                >修改系统图标</el-link
              >
            </div>
          </div>
        </el-row>

        <el-row :gutter="30" style="display: flex">
          <el-table
            class="table1"
            :data="VersionList"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            :cell-style="{
              textAlign: 'center',
            }"
          >
            <el-table-column type="index" min-width="50px"></el-table-column>
            <el-table-column
              label="版本"
              min-width="100px"
              prop="version"
            ></el-table-column>
            <el-table-column label="状态" min-width="100px">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{
                  scope.row.status == 1 ? "已启用" : "已禁用"
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="brief"
              label="描述"
              min-width="200px"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              min-width="150px"
            ></el-table-column>
            <el-table-column label="操作" min-width="150px">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="Edits(scope.row)"
                  >修改</el-button
                >
                <el-button
                  :type="scope.row.status == 1 ? 'danger' : 'success'"
                  size="mini"
                  style="margin-left: 10px"
                  @click="Forbidden(scope.row)"
                  >{{ scope.row.status == 1 ? "禁用" : "启用" }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryinfo.pageNum"
          :page-size="queryinfo.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div v-else>
        <el-row :gutter="30" class="row4">
          <el-col style="text-align: right">
            <el-button
              type="primary"
              icon="el-icon-edit"
              class="div11"
              @click="EditDoc"
              v-show="!showDoc"
              >编辑文档
            </el-button>
          </el-col>
        </el-row>
      </div>

      <el-dialog
        :close-on-click-modal="false"
        :title="titleName"
        :visible.sync="showDoc"
      >
        <TinymceDom
          :ShowButton="ShowButton"
          @closes="Tinymcecloses"
          @saves="Tinymcesaves"
          @input="Tinymceinputs"
        >
        </TinymceDom>
      </el-dialog>

      <el-dialog
        title="保存到 文档1 中?"
        :visible.sync="dialogVisible"
        width="20%"
      >
        <el-input v-model="Docname"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="ConfirmSave">确 定</el-button>
        </span>
      </el-dialog>

      <!--  -->
      <el-dialog
        :close-on-click-modal="false"
        :title="titleName"
        :visible.sync="createDialogVisible"
        width="35%"
        @close="createDialogClosed"
      >
        <el-form
          :model="createForm"
          :rules="createFormRules"
          ref="createFormRef"
          label-width="100px"
        >
          <el-form-item label="版本：" prop="name" v-show="!EditOP">
            <el-input
              v-model.trim="createForm.name"
              placeholder="请输入版本号"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述：" prop="describe">
            <el-input
              type="textarea"
              clearable
              rows="4"
              placeholder="请输入版本描述"
              v-model.trim="createForm.describe"
              maxlength="200"
              @input="DesText"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-row v-for="(item, index) in createForm.resourceList" :key="index">
            <el-form-item
              :label="'图标' + (index + 1) + '：'"
              :prop="'resourceList.' + index + '.icon'"
              :rules="{
                required: true,
                message: '图标不能为空',
                trigger: 'blur',
              }"
            >
              <div style="display: inline-block; float: left; width: 75%">
                <ELselect
                  v-model="item.icon"
                  :options="ToolOptions1"
                  :optionKey="ToolOptionKeys1"
                  Splaceholder="请选择系统图标"
                  @click.native="rowClick(index)"
                  @change="SelectChange"
                ></ELselect>
              </div>
              <div style="display: inline-block">
                <el-button
                  type="danger"
                  size="small"
                  plain
                  @click.prevent="deleteResource(item)"
                  :disabled="
                    createForm.resourceList.length === 1 ? true : false
                  "
                  >删除资源</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              :label="'资源' + (index + 1) + '：'"
              :prop="'resourceList.' + index + '.FileInfo.filename'"
              :rules="{
                required: true,
                message: '资源不能为空',
                trigger: 'blur',
              }"
              @click.native="rowClick1(index)"
              v-show="item.icon"
            >
              <UploadFile
                :FileInfo="item.FileInfo"
                :resourceType="item.FileInfo.resourceType"
                :acceptFile="`${['.AppImage', '.dmg', '.exe', '.jar']}`"
                Splaceholder="文件名以’_操作系统‘结尾，如test_win.exe"
                :nameFlag="true"
              ></UploadFile>
            </el-form-item>
          </el-row>
          <el-form-item style="text-align: left">
            <el-button @click="addResource">新增资源</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="ComfirmAddVersion">保存</el-button>
        </span>
      </el-dialog>

      <!--  -->
      <el-dialog
        :close-on-click-modal="false"
        :title="titleName"
        :visible.sync="SystemIconDialogVisible"
        width="35%"
        @close="SystemIconDialogVisibleClosed"
      >
        <el-form
          :model="SystemIconForm"
          ref="SystemIconFormRef"
          :label-width="EditOP ? '150px' : '100px'"
        >
          <el-row
            v-for="(item, index) in SystemIconForm.SystemIcon"
            :key="index"
          >
            <el-form-item
              :label="'系统名称' + (index + 1) + '：'"
              :prop="'SystemIcon.' + index + '.name'"
              :rules="{
                required: true,
                message: '系统名称不能为空',
                trigger: 'blur',
              }"
              v-show="!EditOP"
            >
              <div style="display: inline-block; float: left; width: 70%">
                <ELselect
                  v-model="item.name"
                  :options="ToolOptions"
                  :optionKey="ToolOptionKeys"
                  Splaceholder="请选择系统名称"
                  @click.native="rowClick(index)"
                  @change="SelectChange"
                ></ELselect>
              </div>
              <div style="display: inline-block">
                <el-button
                  type="danger"
                  size="small"
                  plain
                  @click.prevent="deleteIcon(item)"
                  :disabled="
                    SystemIconForm.SystemIcon.length === 1 ? true : false
                  "
                  >删除图标</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              :label="
                EditOP ? item.name + '图标：' : '系统图标' + (index + 1) + '：'
              "
              :prop="'SystemIcon.' + index + '.logoImage.icon'"
              :rules="{
                required: true,
                message: '系统图标不能为空',
                trigger: 'blur',
              }"
              v-show="item.name"
              @click.native="rowClick1(index)"
            >
              <UploadOneIcon
                :iconInfo="item.logoImage"
                tipss="图片名以’_操作系统‘结尾，如test_win.png"
                :nameFlag="true"
              ></UploadOneIcon>

              <div style="display: flex; margin-top: 5px" v-show="EditOP">
                <el-button
                  type="danger"
                  size="small"
                  plain
                  @click.prevent="deleteIcon(item)"
                  :disabled="
                    SystemIconForm.SystemIcon.length === 1 ? true : false
                  "
                  >删除图标</el-button
                >
              </div>
            </el-form-item>
          </el-row>
          <el-form-item style="text-align: left">
            <el-button @click="addIcon" v-show="!EditOP">新增图标</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="SystemIconDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="ConfirmSystemIcon">保存</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import TinymceDom from '@/components/myself/tinymceEd/TinymceDom.vue'
import ELselect from '@/components/myself/el_select.vue'
import { updateToolVersion, submitToolVersion, getToolVersion, updateToolVersionStatus, getTool, toolVersionListPage, addSystemResource, getSystemIcon, updateSystemResource } from '@/api/toolmanager/toolVersion.js'
import UploadOneIcon from '@/components/myself/UploadCommon/UploadOne.vue'
import UploadFile from '@/components/myself/UploadCommon/UploadFile.vue'

export default {
  components: { TinymceDom, ELselect, UploadFile, UploadOneIcon },
  data () {
    return {
      info: {
        name: '',
        createtime: '',
        describe: '',
        type: '',
        owner_name: '',
      },
      activeIndex: '1',
      VersionList: [],

      queryinfo: {
        name: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      ShowButton: true,
      showDoc: false,
      DomText: '',
      dialogVisible: false,
      Docname: '文档1',

      // 
      createDialogVisible: false,
      createForm: {
        name: '',
        describe: '',
        resourceList: [],
        ResourceObj: {
          FileInfo: {
            filename: '',
            resPackage: {},
            resourceType: '',
          },
          icon: ''
        }
      },
      createFormRules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ],
        describe: [
          { required: true, message: '描述不能为空', trigger: 'blur' },

        ],
      },
      wordnum: 0,

      ToolOptionKeys: {
        value: 'name',
        label: 'name',
        label2: ''
      },
      ToolOptions: [
        {
          name: 'windows'
        },
        {
          name: 'macos'
        },
        {
          name: 'linux'
        },
      ],
      ToolOptions1: [],
      ToolOptionKeys1: {
        value: 'tool_os',
        label: 'tool_os',
        label2: ''
      },

      SystemIconDialogVisible: false,
      SystemIconForm: {
        SystemIcon: [],
        SystemObj: {
          logoImage: {
            icon: '',
            iconSize: '',
            IconObject: {},
            acceptImage: ".png,.jpg,.gif,.bmp",
            type: ''
          },
          name: ''
        }

      },
      titleName: '',

      toolID: '',
      uploadSuccess: false,
      iconIndex: '',
      EditOP: false,
      versionID: '',
    }
  },
  mounted () {
    this.toolID = this.$route.query.id
    this.detailId()
    this.getList()
  },
  methods: {
    async detailId () {
      const res = await getTool(this.toolID)
      if (res.status !== 200) return this.$message.error('获取详情失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.info.name = res.data.data.toolBase.name
      this.info.createtime = res.data.data.toolBase.createTime
      this.info.describe = res.data.data.toolBase.brief
      this.info.type = res.data.data.toolBase.type
      this.info.owner_name = res.data.data.toolBase.owner_name
    },
    async getList () {
      const res = await toolVersionListPage(this.toolID, this.queryinfo.pageNum, this.queryinfo.pageSize, this.queryinfo.name)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.VersionList = res.data.data.list
      this.total = res.data.data.total
    },
    Versions () {
      this.activeIndex = '1'
      this.showDoc = false
    },
    Docs () {
      this.activeIndex = '2'
    },

    async Edits (para) {
      this.versionID = para.id
      this.EditOP = true
      this.titleName = '修改版本'
      const res = await getToolVersion(para.id)
      if (res.status !== 200) return this.$message.error('获取详情失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.createForm.describe = res.data.data.brief
      this.createForm.name = res.data.data.version
      res.data.data.installerPack.forEach((item, index) => {
        this.createForm.resourceList.push(JSON.parse(JSON.stringify(this.createForm.ResourceObj)))
        this.createForm.resourceList[index].FileInfo.filename = item.pathName.substr(item.pathName.lastIndexOf("/") + 1)
        this.createForm.resourceList[index].FileInfo.resourceType = item.os
        this.createForm.resourceList[index].icon = item.os.split('-')[0] + '-icon'
        this.$set(this.createForm.resourceList[index].FileInfo, 'resourceId', item.id)
        this.$set(this.createForm.resourceList[index].FileInfo, 'pathName', item.pathName)
      });

      this.ToolOptions1 = await this.getIcon()
      this.createDialogVisible = true
    },
    async Forbidden (para) {
      const res = await updateToolVersionStatus(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

    EditDoc () {
      this.showDoc = true
      this.titleName = '编辑文档'
    },

    // tinymce
    Tinymcecloses () {
      if (this.DomText.search('<p>') != -1) {
        this.dialogVisible = true
      } else {
        this.showDoc = false
      }
    },
    Tinymcesaves () {
      this.dialogVisible = true
    },
    // tinymec输入的内容
    Tinymceinputs (text) {
      this.DomText = text
    },

    ConfirmSave () {
      this.dialogVisible = false
      this.showDoc = false
    },

    // 
    async getIcon () {
      const res = await getSystemIcon(this.toolID)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      return res.data.data
    },
    // 
    async AddVersion () {
      this.EditOP = false
      this.titleName = '新增版本'
      // 深拷贝，不然会同步更新
      this.createForm.resourceList.push(JSON.parse(JSON.stringify(this.createForm.ResourceObj)))
      this.ToolOptions1 = await this.getIcon()
      this.createDialogVisible = true
    },
    createDialogClosed () {
      this.$refs.createFormRef.resetFields()
      this.createForm.resourceList = []
    },
    DesText () {
      this.wordnum = this.createForm.describe.length
    },

    // 
    rowClick (index) {
      this.iconIndex = index
    },
    rowClick1 (index) {
      this.iconIndex = index
    },

    SelectChange (value) {
      if (this.createDialogVisible) {
        this.createForm.resourceList[this.iconIndex].FileInfo = JSON.parse(JSON.stringify(this.createForm.ResourceObj.FileInfo))
        this.createForm.resourceList[this.iconIndex].FileInfo.resourceType = value.split('-')[0] + '-pack'
      } else {
        this.SystemIconForm.SystemIcon[this.iconIndex].logoImage = JSON.parse(JSON.stringify(this.SystemIconForm.SystemObj.logoImage))
        this.SystemIconForm.SystemIcon[this.iconIndex].logoImage.type = value + '-icon'
      }
    },

    addResource () {
      this.createForm.resourceList.push(JSON.parse(JSON.stringify(this.createForm.ResourceObj)))
    },
    deleteResource (item) {
      var index = this.createForm.resourceList.indexOf(item)
      if (index !== -1) {
        this.createForm.resourceList.splice(index, 1)
      }
    },

    ComfirmAddVersion () {
      this.$refs.createFormRef.validate(async valid => {
        if (valid) {
          let result = []
          this.createForm.resourceList.forEach(item => {
            // 存在就是新增/修改
            if (item.FileInfo.resPackage.pathName) {
              result.push(item.FileInfo.resPackage)
            } else {
              result.push({ resourceId: item.FileInfo.resourceId, resourceType: item.FileInfo.resourceType, pathName: item.FileInfo.pathName })
            }


          });
          let quiese = { id: this.toolID, versionInfo: { brief: this.createForm.describe, version: this.createForm.name, installerPack: result } }
          if (!this.EditOP) {
            const res = await submitToolVersion(quiese)
            if (res.status !== 200) return this.$message.error('新增失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
          } else {
            const res = await updateToolVersion(quiese)
            if (res.status !== 200) return this.$message.error('操作失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
          }
          this.$message.success('新增成功')
          this.createDialogVisible = false
          this.getList()
        }
      })
    },

    // 系统图标
    UploadIcon () {
      this.titleName = '上传工具系统图标'
      this.EditOP = false
      this.SystemIconForm.SystemIcon.push(JSON.parse(JSON.stringify(this.SystemIconForm.SystemObj)))
      this.SystemIconDialogVisible = true
    },
    SystemIconDialogVisibleClosed () {
      this.$refs.SystemIconFormRef.resetFields()
      this.SystemIconForm.SystemIcon = []
    },

    ConfirmSystemIcon () {
      this.$refs.SystemIconFormRef.validate(async valid => {
        if (valid) {
          let result = []
          this.SystemIconForm.SystemIcon.forEach(item => {
            // 存在就是新增/修改
            if (item.logoImage.IconObject.pathName) {
              result.push(item.logoImage.IconObject)
            } else {
              result.push({ resourceId: item.logoImage.resourceId, resourceType: item.logoImage.type, pathName: item.logoImage.pathName })
            }
          })

          let quiese = { id: this.toolID, systemIconList: result }
          if (!this.EditOP) {
            const res = await addSystemResource(quiese)
            if (res.status !== 200) return this.$message.error('获取失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
          } else {
            const res = await updateSystemResource(quiese)
            if (res.status !== 200) return this.$message.error('获取失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
          }

          this.$message.success('操作成功')
          this.SystemIconDialogVisible = false
        }
      })
    },

    addIcon () {
      this.SystemIconForm.SystemIcon.push(JSON.parse(JSON.stringify(this.SystemIconForm.SystemObj)))
    },
    deleteIcon (item) {
      var index = this.SystemIconForm.SystemIcon.indexOf(item)
      if (index !== -1) {
        this.SystemIconForm.SystemIcon.splice(index, 1)
      }
    },

    async ModeifyIcon () {
      let icons = await this.getIcon()
      if (icons.length <= 0) {
        this.$message.info('请先上传系统图标')
      } else {
        this.SystemIconDialogVisible = true
        this.titleName = '修改工具系统图标'
        this.EditOP = true

        // 回显
        icons.forEach((item, index) => {
          this.SystemIconForm.SystemIcon.push(JSON.parse(JSON.stringify(this.SystemIconForm.SystemObj)))
          this.SystemIconForm.SystemIcon[index].name = item.tool_os.split('-')[0]
          this.SystemIconForm.SystemIcon[index].logoImage.icon = item.url
          this.SystemIconForm.SystemIcon[index].logoImage.type = item.tool_os
          this.$set(this.SystemIconForm.SystemIcon[index].logoImage, 'resourceId', item.id)
          this.$set(this.SystemIconForm.SystemIcon[index].logoImage, 'pathName', item.pathName)
        })
      }

    },

  }
}
</script>
<style lang="less" scoped>
.row {
  text-align: left;
  margin-top: 10px;
}
.span1 {
  margin-left: 60px;
  font-size: 16px;
  font-weight: 700;
}
.row1 {
  text-align: left;
}
.span2 {
  margin-left: 160px;
  font-size: 14px;
  margin-right: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row3 {
  text-align: left;
  margin-top: 60px;
}
.link1 {
  margin-left: 60px;
  font-weight: 700;
  font-size: 16px;
  margin-right: 40px;
}
.link2 {
  font-weight: 700;
  font-size: 16px;
}
.link1:hover {
  color: blue;
}

.row4 {
  margin-top: 60px;
}

.table1 {
  margin-left: 60px;
  margin-right: 60px;
}

.div11 {
  margin-left: 60px;
  margin-right: 60px;
}
// .tinymces {
//   margin-top: 20px;
// }
</style>