<template>
  <div>
    <div style="display: inline-block; float: left; width: 75%">
      <el-input
        v-model.trim="FileInfo.filename"
        :placeholder="Splaceholder"
        :readonly="true"
        disabled
      ></el-input>
      <div style="text-align: left">
        {{ tipss }}
      </div>
    </div>
    <div style="display: inline-block">
      <el-upload
        ref="uploadRef"
        action="#"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :accept="acceptFile"
        v-loading="loading"
        element-loading-text="文件正在上传中，请稍等"
      >
        <el-button size="small" type="primary" class="FileImg" plain
          >选择文件
        </el-button>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { UploadOssResource } from '@/libs/ossUpload/getUrl'
import { SpecChar } from '@/components/myself/speciaChaFilt.js'
import { NameRules } from './nameRule.js'
export default {
  props: {
    FileInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    tipss: {
      type: String
    },
    acceptFile: {
      type: String,
    },
    Splaceholder: {
      type: String,
      default: '请选择上传的文件'
    },
    resourceType: {
      type: String,
      default: 'audit-package'
    },
    nameFlag: {
      type: Boolean,
      default: false
    },
    limitSize: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    // 在确定上传之前，判断
    beforeUploadFile (file) {
      if (NameRules(file.name, this.Splaceholder, this.FileInfo.resourceType, this.nameFlag) && SpecChar(file.name)) {
        let fileType = file.name.substr(file.name.lastIndexOf("."))
        let fileMsg = this.acceptFile
        let extension = fileMsg.toLowerCase().includes(fileType.toLowerCase())
        if (!extension) {
          return this.$message.error('上传的文件类型仅支持 ' + fileMsg + '格式!')
        }
        if (!(file.size < 1024 * 1024 * this.limitSize)) {
          return this.$message.error(`上传的文件大小不能超过 ${this.limitSize}MB!`)
        }
        return true
      }
    },
    async beforeUpload (file) {
      if (this.beforeUploadFile(file) === true) {
        this.loading = true
        const res = await UploadOssResource(file, this.resourceType)
        let filename = file.name
        let quiese = { pathName: res.url2, size: file.size, resourceType: this.resourceType }

        this.FileInfo.filename = filename
        this.FileInfo.resPackage = quiese
        this.loading = false
        this.$forceUpdate()
      }
    },

  }
}
</script>
<style lang="less" scoped>
.FileImg {
  margin-left: 5px;
}
</style>

