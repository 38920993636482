import axios from '@/libs/api.request'
// import qs from 'qs'

// 添加工具类型
export const addToolType = (name) => {
  let uri = '/system/tool/addToolType'
  return axios.request({
    url: uri,
    method: 'put',
    params: { name: name }
  })
}

// 工具列表所有的
export const ALLtoolList = () => {
  let uri = '/system/tool/getList'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 工具列表
export const toolListPage = (name, pageNum, pageSize) => {
  let uri = '/system/tool/toolList'
  return axios.request({
    url: uri,
    method: 'get',
    params: { name: name, pageNum: pageNum, pageSize: pageSize }
  })
}


// 工具类型列表
export const toolTypeList = () => {
  let uri = '/system/tool/toolTypeList'
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 工具类型列表
export const toolTypeListPage = (pageNum, pageSize) => {
  let uri = '/system/tool/toolTypePage/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 工具版本列表
export const toolVersionListPage = (id, pageNum, pageSize, version) => {
  let uri = '/system/tool/toolVersionList'
  return axios.request({
    url: uri,
    method: 'get',
    params: { id: id, pageNum: pageNum, pageSize: pageSize, name: version },
    // versionInfo.version: version
    // params: { id: id, pageNum: pageNum, pageSize: pageSize, versionInfo: { version: version } },
    // paramsSerializer: function (params) {
    //   return qs.stringify(params, { arrayFormat: 'brackets', allowDots: true })
    // }

  })
}


// 修改工具类型
export const updateToolType = (toolType) => {
  let uri = '/system/tool/updateToolType'
  return axios.request({
    url: uri,
    method: 'put',
    data: toolType
  })
}

// 提交工具版本信息
export const submitToolVersion = (info) => {
  let uri = '/system/tool/version/submitToolVersionInfo'
  return axios.request({
    url: uri,
    method: 'put',
    data: info
  })
}

// 修改工具版本信息
export const updateToolVersion = (info) => {
  let uri = '/system/tool/version/updateToolVersionInfo'
  return axios.request({
    url: uri,
    method: 'put',
    data: info
  })
}
// 工具版本启用禁用
export const updateToolVersionStatus = (id) => {
  let uri = '/system/tool/version/updateToolVersionStatus'
  return axios.request({
    url: uri,
    method: 'put',
    params: { id: id }
  })
}



// 工具启用禁用
export const updateToolStatus = (id) => {
  let uri = '/system/tool/version/updateToolStatus'
  return axios.request({
    url: uri,
    method: 'put',
    params: { id: id }
  })
}



// 工具详情
export const getTool = (id) => {
  let uri = '/system/tool/getTool/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 工具版本详情
export const getToolVersion = (id) => {
  let uri = '/system/tool/getToolVersion/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 添加系统图标
export const addSystemResource = (toolRequest) => {
  let uri = '/system/tool/addSystemResource'
  return axios.request({
    url: uri,
    method: 'put',
    data: toolRequest
  })
}

// 获取系统图标
export const getSystemIcon = (id) => {
  let uri = '/system/tool/getSystemIcon/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 修改系统图标
export const updateSystemResource = (toolRequest) => {
  let uri = '/system/tool/updateSystemResource'
  return axios.request({
    url: uri,
    method: 'put',
    data: toolRequest
  })
}


// ***************************************************************低代码
// 添加/修改套餐
export const addPackage = (toolPackageDTO) => {
  let uri = '/system/tool/toolPackage/addOrUpdatePackage'
  return axios.request({
    url: uri,
    method: 'put',
    data: toolPackageDTO
  })
}
// 低代码套餐启用禁用
export const updateToolPackageStatus = (id) => {
  let uri = '/system/tool/toolPackage/updateToolPackageStatus/' + id
  return axios.request({
    url: uri,
    method: 'put',
    data: id
  })
}

// 低代码套餐列表分页
export const toolPackagePage = (pageNum, pageSize) => {
  let uri = '/system/tool/toolPackage/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}
// 套餐详情
export const getToolPackageId = (id) => {
  let uri = '/system/tool/toolPackage/getToolPackage/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 删除套餐
export const deletePackage = (id) => {
  let uri = '/system/tool/toolPackage/deletePackage/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}


// 添加/修改工具种类
export const addPackageKind = (toolPackageDTO) => {
  let uri = '/system/tool/toolPackageKind/addOrUpdatePackageKind'
  return axios.request({
    url: uri,
    method: 'put',
    data: toolPackageDTO
  })
}
// 删除工具种类
export const deletePackageKind = (id) => {
  let uri = '/system/tool/toolPackageKind/delete/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}
// 工具种类详情
export const getToolPackageKind = (id) => {
  let uri = '/system/tool/toolPackageKind/getToolPackageKind/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 套餐类型列表:种类列表
export const getToolPackageKindList = () => {
  let uri = '/system/tool/toolPackageKind/getToolPackageKind'
  return axios.request({
    url: uri,
    method: 'get'
  })
}


// 
// 添加工具权限
export const addPermission = (toolPermission) => {
  let uri = '/system/tool/toolPermission/addPermission'
  return axios.request({
    url: uri,
    method: 'put',
    data: toolPermission
  })
}
// 删除工具权限
export const deletePermission = (id) => {
  let uri = '/system/tool/toolPermission/deletePermission/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}
// 低代码工具权限列表
export const getPermission = (kindId) => {
  let uri = '/system/tool/toolPermission/getPermission'
  return axios.request({
    url: uri,
    method: 'get',
    params: { packageKindId: kindId }
  })
}
// 低代码工具套餐权限详情(套餐编辑)
export const getPermissionByPackage = (packageId) => {
  let uri = '/system/tool/toolPermission/getPermissionByPackage/' + packageId
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 权限详情
export const getToolPermission = (id) => {
  let uri = '/system/tool/toolPermission/getToolPermission/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}
// 修改工具权限
export const updatePermission = (info) => {
  let uri = '/system/tool/toolPermission/updatePermission'
  return axios.request({
    url: uri,
    method: 'put',
    data: info
  })
}


// 低代码工具价格设置
export const batchUpdatePrice = (quiese) => {
  let uri = '/system/tool/toolPrice/batchUpdatePrice'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}
// 低代码工具收费详情
export const getPrice = () => {
  let uri = '/system/tool/toolPrice/getPrice'
  return axios.request({
    url: uri,
    method: 'get'
  })
}


